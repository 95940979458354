import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import React from 'react';
import styled from 'styled-components';

export default function AccountMenu(props) {
    const {name, signOut} = props;
    
    return (
        <Container>
            <StyledAccountCircleIcon/>
            <UserName>
                {name}
            </UserName>
            <Divider />
            <FullWidthList>
                <ListItem>
                    <ListItemButton onClick={signOut}>
                        <ListItemIcon>
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Sign out">
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </FullWidthList>
        </Container>
    )
}

const Container = styled.div`
    width: 320px;
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    flex-direction: column;
    align-items: center;
`;

const StyledAccountCircleIcon = styled(AccountCircleIcon)`
    min-height: 128px;
    min-width: 128px;
`;

const UserName = styled.div`
    margin-top: 24px;
    font-size: 18px;
`;

const Divider = styled.div`
    width: 90%;
    margin-top: 24px;
    border-bottom: 1px solid rgba(0,0,0,0.25);
`;

const FullWidthList = styled(List)`
    width: 100%;
`;