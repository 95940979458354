import React from 'react';
import { Button, IconButton, Modal, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { API } from 'aws-amplify';

export default function CustomModal(props) {
    const {
        onClose,
        handleAction,
        actionText,
        actionStyle,
        title,
        children,
        ...rest
    } = props;
    return (
        <StyledModal
            {...rest}
        >
            <StyledContainer>
                <Header>
                    <div style={{width: 40}}/>
                    <Typography variant="h6">
                        {title}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Header>
                <Divider/>
                <Body>
                    {children}
                    <StyledButton style={actionStyle || {}} variant="contained" onClick={handleAction}>{actionText || 'Submit'}</StyledButton>
                </Body>
            </StyledContainer>
        </StyledModal>
    )
}

const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledContainer = styled.div`
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #fff;
    border-radius: 10px;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Divider = styled.div`
    width: 95%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`;

const Body = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 26px;
    box-sizing: border-box;
    > :not(:first-child) {
        margin-top: 24px;
    }
`;

const StyledButton = styled(Button)`
    align-self: flex-end;
`;