import React from 'react';
import { Button, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import {} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CastleIcon from '@mui/icons-material/Castle';
import HomeIcon from '@mui/icons-material/Home';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useCampaign } from '../../contexts/CampaignContext';
import { useDrawerContext } from '../../contexts/DrawerContext';

export default function NavDrawer(props) {
    const {campaign} = useCampaign();
    const {drawerOpen, handleDrawerOpenChange} = useDrawerContext();
    const history = useHistory();

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        handleDrawerOpenChange(open);
    }

    const listItems = [
        {
            icon: HomeIcon,
            text: 'Home',
            href: '/',
        },
        {
            icon: CastleIcon,
            text: 'My Campaigns',
            href: '/campaigns',
        },
        {
            icon: DateRangeIcon,
            text: 'Scheduler',
            href: '/scheduler',
        },
    ];
    return (
        // <div>
            <StyledDrawer
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                <Title>
                    <div style={{width: 40, height: 40}}></div>
                    <div style={{marginBottom: 4}}>
                        D&amp;Data
                    </div>
                    <CloseIconButton
                        onClick={toggleDrawer(false)}
                    >
                        <CloseIcon/>
                    </CloseIconButton>
                </Title>
                <Divider/>
                <StyledList>
                    {
                        listItems.map((item) => {
                            const ItemIcon = item.icon;
                            return (
                                <ListItem>
                                    <ListItemButton disabled={item.disabled} onClick={ () => history.push(item.href)}>
                                        <StyledListItemIcon>
                                            <ItemIcon/>
                                        </StyledListItemIcon>
                                        <ListItemText>
                                            {item.text}
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            );
                        })
                    }
                </StyledList>
                <div style={{marginTop: 'auto'}}/>
                <Divider/>
                <CampaignDisplay>
                    {
                        campaign.name ?
                        <React.Fragment>
                            <CampaignName>{campaign.name}</CampaignName>
                            <Divider/>
                            <CharacterName>{campaign.characterName}</CharacterName>
                        </React.Fragment>
                        :
                        <StyledButton variant="contained" onClick={() => {
                            history.push('/campaigns');
                            toggleDrawer(false)();
                        }}>
                            Select a campaign
                        </StyledButton>
                    }
                </CampaignDisplay>
            </StyledDrawer>
        // </div>
    );
}

const StyledIconButton = styled(IconButton)`
    position: absolute;
    left: 8px;
    top: -44px;
    z-index: 1000;
`;

const StyledDrawer = styled(Drawer)`
    width: 256px;
    & .MuiDrawer-paper {
        width: 256px;
        background: #354555;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 8px;
    width: 256px;
    height: 48px;
    color: white;
    font-size: 24px;
`;

const CloseIconButton = styled(IconButton)`
    .MuiSvgIcon-root {
        color: white;
    }
`;

const Divider = styled.div`
    width: 95%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
`;

const StyledList = styled(List)`
    width: 100%;
    color: white;
`;

const StyledListItemIcon = styled(ListItemIcon)`
    .MuiSvgIcon-root {
        color: white;
    }
`;

const CampaignDisplay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 48px;
    padding: 8px;
    color: white;
`;

const CampaignName = styled.div`
    font-size: 18px;
    margin-bottom: 4px;
    text-align: center;
`;

const CharacterName = styled.div`
    margin-top: 4px;
    font-size: 14px;
    text-align: center;
`;

const StyledButton = styled(Button)`
    background: #fff;
`;