import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export default function Scheduler(props) {

    return (
        <div style={{flex: 1}}>
            <PageContainer>
                <Typography variant="h3">
                    Scheduler
                </Typography>
                <Divider/>
                <Typography variant="h4">
                    Under Construction
                </Typography>
            </PageContainer>
        </div>
    )
}

const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 24px;
    background: #f3f3f3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const Divider = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
    width: 80%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`;