import React from 'react';
import { Button, Card, CircularProgress, Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import PageContainer from '../../utils/PageContainer';
import CampaignEmptyState from './CampaignEmptyState';
import CampaignCard from './CampaignCard';
import Divider from '../../utils/Divider';

export default function CampaignList(props) {
    const { handleCreateCampaign, handleCampaignsChange, campaigns } = props;
    console.log(campaigns);
    return (
        <PageContainer justify="start">
            <Typography variant="h3">My Campaigns</Typography>
            <Divider/>
            {
                campaigns === null ?
                <CircularProgress/>
                :
                (
                    campaigns.length > 0 ?
                    <Grid container spacing={2}>
                        {
                            campaigns.map(campaign => (
                                <Grid item key={campaign.id}>
                                    <CampaignCard campaign={campaign} handleDelete={handleCampaignsChange}/>
                                </Grid>
                            ))
                        }
                        <Grid item>
                            <StyledButton xs item variant="outlined" onClick={handleCreateCampaign}>Create a campaign</StyledButton>
                        </Grid>
                    </Grid>
                    :
                    <CampaignEmptyState handleCreateCampaign={handleCreateCampaign}/>
                )
            }
        </PageContainer>
    );
}

const StyledButton = styled(Button)`
    width: 256px;
    height: 256px;
`;