import React from 'react';
import { Button, Typography } from '@mui/material';
import CastleOutlinedIcon from '@mui/icons-material/CastleOutlined';
import styled from 'styled-components';
import PageContainer from '../../utils/PageContainer';

export default function CampaignEmptyState(props) {
    const { handleCreateCampaign } = props;
    return (
        <PageContainer>
            <StyledCastleOutlinedIcon/>
            <Typography variant="h3">
                You don't have any campaigns yet!
            </Typography>
            <Divider/>
            <Typography variant="h5">
                Create a new campaign, or follow a join link to get started
            </Typography>
            <StyledButton variant="contained" onClick={handleCreateCampaign}>Create a campaign</StyledButton>
        </PageContainer>
    );
}

const Divider = styled.div`
    width: 80%;
    margin-top: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`;

const StyledCastleOutlinedIcon = styled(CastleOutlinedIcon)`
    min-height: 128px;
    min-width: 128px;
    margin-bottom: 24px;
`;

const StyledButton = styled(Button)`
    margin-top: 24px !important;
`;