import React from 'react';

const DrawerContextContext = React.createContext(null);

const useDrawerContext = () => {
    const [
        drawerOpen,
        setDrawerOpen
    ] = React.useContext(DrawerContextContext);

    const handleDrawerOpenChange = (value) => {
        console.trace(value);
        setDrawerOpen(value);
    }

    return {drawerOpen, handleDrawerOpenChange};
}

const withDrawerContext = (Component) => (props) => {
    const {drawerOpen, handleDrawerOpenChange} = useDrawerContext();
    return (
        <Component
            {...props}
            drawerOpen={drawerOpen} 
            handleDrawerOpenChange={handleDrawerOpenChange}
        />
    );
};

const DrawerContextProvider = ({ children }) => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    return (
        <DrawerContextContext.Provider value={[drawerOpen, setDrawerOpen]}>
            {children}
        </DrawerContextContext.Provider>
    );
};

export { useDrawerContext, withDrawerContext, DrawerContextProvider };