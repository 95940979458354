import { Button, Card, Grid, Typography } from '@mui/material';
import { API } from 'aws-amplify';
import React, {useEffect, useState} from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import { useCampaign } from '../../../contexts/CampaignContext';
import PageContainer from '../../utils/PageContainer';
import CampaignDetails from './CampaignDetails';
import CampaignEmptyState from './CampaignEmptyState';
import CampaignJoin from './CampaignJoin';
import CampaignList from './CampaignList';
import CreateCampaignModal from './CreateCampaignModal';

export default function MyCampaigns(props) {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const { handleCampaignChange } = useCampaign();
    
    // TODO: pull data from API/DB
    const [myCampaigns, setMyCampaigns] = useState(null);

    const handleCreateModalClose = () => {
        setCreateModalOpen(false);
    };

    const handleCreateModalOpen = () => {
        setCreateModalOpen(true);
    };

    const handleSaveSuccess = () => {
        updateCampaigns();
    }

    const updateCampaigns = async () => {
        const res = await API.get('campaignsApi', '/campaigns');
        setMyCampaigns(res);
    }

    useEffect(() => {
        updateCampaigns();
    }, []);

    console.log(myCampaigns);
    return (
        <React.Fragment>
            <CreateCampaignModal open={createModalOpen} onClose={handleCreateModalClose} handleSave={handleSaveSuccess}/>
            <Switch>
                <Route exact path="/campaigns">
                    <CampaignList campaigns={myCampaigns} handleCreateCampaign={handleCreateModalOpen} handleCampaignsChange={updateCampaigns}/>
                </Route>
                <Route exact path="/campaigns/:campaignId/join">
                    <CampaignJoin onCampaignJoin={updateCampaigns}/>
                </Route>
                <Route path="/campaigns/:campaignId">
                    <CampaignDetails campaigns={myCampaigns}/>
                </Route>
            </Switch>
        </React.Fragment>
    );
}