import React from 'react';
import logo from './logo.svg';
import AppRouter from './components/navigation/AppRouter';
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';
import styled from 'styled-components';
import { BrowserRouter as Router} from 'react-router-dom';
import HeaderBar from './components/navigation/HeaderBar';
import { CampaignProvider } from './contexts/CampaignContext';
import { DrawerContextProvider } from './contexts/DrawerContext';
import NavDrawer from './components/navigation/NavDrawer';

function App() {
  return (
    <Authenticator>
      {
        ({signOut, user}) => (
          <Router>
            <CampaignProvider>
              <DrawerContextProvider>
                <NavDrawer/>
                <HeaderBar signOut={signOut} user={user}/>
                <AppRouter />
              </DrawerContextProvider>
            </CampaignProvider>
          </Router>
        )
      }
    </Authenticator>
  );
}

export default App;
