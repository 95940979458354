import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePage from '../pages/HomePage';
import MyCampaigns from '../pages/Campaigns/MyCampaigns';
import Scheduler from '../pages/Scheduler';

function AppRouter(props) {
    return (
        <Switch>
            <Route exact={true} path="/" component={HomePage} />
            <Route path="/campaigns" component={MyCampaigns} />
            <Route exact={true} path="/scheduler" component={Scheduler} />
        </Switch>
    )
}

export default AppRouter;