import React from 'react';
import { IconButton, Popover, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import styled from 'styled-components';
import AccountMenu from './AccountMenu';
import { useCampaign } from '../../contexts/CampaignContext';
import { useDrawerContext } from '../../contexts/DrawerContext';

export default function HeaderBar(props) {
    const {signOut, user} = props;
    const {campaign} = useCampaign();
    const {handleDrawerOpenChange} = useDrawerContext();
    const userName = user.name || user.attributes.email;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleAccountMenuPopoverClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleAccountMenuPopoverClose = () => {
        setAnchorEl(null);
    }

    return (
        <HeaderContainer>
            
            <IconButton
                onClick={() => handleDrawerOpenChange(true)}
            >
                <MenuIcon />
            </IconButton>
            <Typography variant="h6">{campaign.name}</Typography>
            <IconButton onClick={handleAccountMenuPopoverClick}>
                <AccountCircleIcon/>
            </IconButton>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleAccountMenuPopoverClose}
            >
                <AccountMenu name={userName} signOut={signOut}/>
            </Popover>
        </HeaderContainer>
    );
}

const HeaderContainer = styled.div`
    width: 100%;
    height: 48px;
    background: #fff;
    box-sizing: border-box;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.25);
    z-index: 1000;
`;