import React from 'react';

const CampaignContext = React.createContext(null);

const useCampaign = () => {
    const [campaign, setCampaign] = React.useContext(CampaignContext);

    const handleCampaignChange = (value) => {
        setCampaign(value);
    }

    return {campaign, handleCampaignChange};
}

const withCampaign = (Component) => (props) => {
    const {campaign, handleCampaignChange} = useCampaign();
    return ( 
        <Component
            {...props}
            campaign={campaign}
            handleCampaignChange={handleCampaignChange}
        />
    );
};

const CampaignProvider = ({ children }) => {
    const [campaign, setCampaign] = React.useState({});
    return (
        <CampaignContext.Provider value={[campaign, setCampaign]}>
            {children}
        </CampaignContext.Provider>
    );
};

export { useCampaign, withCampaign, CampaignProvider };