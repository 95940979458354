import React from 'react';
import styled from 'styled-components';

export default styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 24px;
    background: #f3f3f3;
    display: flex;
    overflow-y: auto;
    flex-direction: ${props => props.direction || 'column'};
    align-items: ${props => props.align || 'center'};
    justify-content: ${props => props.justify || 'center'};
    text-align: ${props => props.textAlign || 'center'};
`;