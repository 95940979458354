import { Link, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export default function HomePage(props) {
    
    return (
        <div style={{flex: 1}}>
            <PageContainer>
                <Typography variant="h2" style={{marginBottom: '20%'}}>
                    D&amp;Data
                </Typography>
                <Typography variant="subtitle2" style={{maxWidth: '69%'}}>
                    Welcome, things are still a work in progress. The first
                    feature I will be working on is campaign management and
                    a scheduler to find availablity for a session within a
                    campaign. I will eventually add a roadmap as well.
                </Typography>
                <Divider/>
                <Typography variant="subtitle2" style={{maxWidth: '69%'}}>
                    Open the drawer (top left) to start your journey
                </Typography>
            </PageContainer>
        </div>
    );
}

const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 24px;
    background: #f3f3f3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const Divider = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
    width: 80%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`;