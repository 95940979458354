import { Button, Card, Typography } from '@mui/material';
import { API } from 'aws-amplify';
import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import CustomModal from '../../utils/CustomModal';

export default function CampaignCard(props) {
    const {campaign, handleDelete} = props;
    const history = useHistory();
    const [deleteModalOpen, setDeleteModalOpen] = useState();
    const deleteCampaign = async () => {
        const res = await API.del('campaignsApi', `/campaigns/object/${campaign.id}`);
        handleDelete();
    }

    return (
        <StyledCard>
            {/* {campaign.createdBy}
            {campaign.id} */}
            <CustomModal
                title={`Delete ${campaign.name}?`}
                open={deleteModalOpen}
                campaignId={campaign.id}
                actionText="Delete"
                handleAction={deleteCampaign}
                onClose={() => setDeleteModalOpen(false)}
            >
                Are you sure you want to delete this campaign?
            </CustomModal>
            <Typography variant="h5" noWrap style={{width: '100%'}}>
                <b title={campaign.name}>{campaign.name}</b>
            </Typography>
            <Divider/>
            <Typography>
                <b>Created:</b> {
                  (new Date(campaign.createdAt).getMonth() + 1) + '/'
                + new Date(campaign.createdAt).getDate() + '/'
                + new Date(campaign.createdAt).getFullYear()
                }
            </Typography>
            <Typography>
                {
                    campaign.createdAt === campaign.createdBy ?
                    `Updated: ${new Date(campaign.updatedAt).getMonth() + 1}
                     /${new Date(campaign.updatedAt).getDate()}
                     /${new Date(campaign.updatedAt).getFullYear()}
                    ` : null
                }
            </Typography>
            <DescriptionContainer>
                <Typography>
                    {campaign.description}
                </Typography>
            </DescriptionContainer>
            <ActionsContainer>
                <Button onClick={() => history.push(`/campaigns/${campaign.id}`)}>View Details</Button>
                <Button onClick={() => setDeleteModalOpen(true)}>Delete</Button>
            </ActionsContainer>
        </StyledCard>
    );
}

const StyledCard = styled(Card)`
    width: 256px;
    height: 256px;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Divider = styled.div`
    margin-top: 12px;
    margin-bottom: 12px;
    width: 95%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`;

const DescriptionContainer = styled.div`
    padding: 8px;
    flex: 1;
    font-size: 14px;
    max-height: 100px;
    overflow-y: auto;
`;

const ActionsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;