import React from 'react';
import { Button, IconButton, Modal, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { API } from 'aws-amplify';
import CustomModal from '../../utils/CustomModal';

export default function CreateCampaignModal(props) {
    const { onClose, handleSave, ...rest } = props;
    const [campaignName, setCampaignName] = useState();
    const [campaignDescription, setCampaignDescription] = useState();
    const [nameError, setNameError] = useState();
    const handleClose = () => {
        onClose();
        setCampaignName('');
        setCampaignDescription('');
        setNameError(false);
    }
    const handleNameChange = (e) => {
        setCampaignName(e.target.value);
        if (nameError) {
            setNameError(false);
        }
    }
    const handleDescriptionChange = (e) => {
        setCampaignDescription(e.target.value);
    }

    const handleSubmit = async () => {
        // TODO: post new campaign, somehow update MyCampaigns
        if (!campaignName) {
            setNameError(true);
            return;
        }
        const result = await API.post('campaignsApi', '/campaigns', {
            body: {
                name: campaignName,
                description: campaignDescription,
            }
        });
        console.log(result);
        if (result.success) {
            handleSave();
            handleClose();
        }
        else {
            alert('Error creating campaign');
            // TODO: good error handling
        }
    }
    return (
        <CustomModal
            onClose={handleClose}
            handleAction={handleSubmit}
            title="Create campaign"
            actionText="Create"
            {...rest}
        >
            <TextField
                value={campaignName}
                onChange={handleNameChange}
                label="Campaign Name"
                size="small"
                error={nameError}
                helperText={nameError ? 'required' : ''}
            />
            <TextField
                value={campaignDescription}
                onChange={handleDescriptionChange}
                label="Description (optional)"
                multiline
                rows={4}
            />
        </CustomModal>
    )
}