import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Divider from '../../utils/Divider';
import PageContainer from '../../utils/PageContainer';

export default function CampaignDetails(props) {
    const {campaigns} = props;
    const {campaignId} = useParams();
    const templateCampaign = {
        name: '...',
        createdAt: '...',
        createdBy: '...',
        description: '...',
    }
    console.log(campaigns);
    const selectedCampaign = (campaigns && campaigns.filter(campaign => campaign.id === campaignId)[0]) || templateCampaign;
    return (
        <PageContainer justify="start">
            <Typography variant="h3" noWrap style={{width: '100%'}}>{selectedCampaign ? selectedCampaign.name : '...'}</Typography>
            <Divider/>
            <StyledCard>
                <LineItem>
                    <Typography variant="h5">Full Name:</Typography>
                    <Typography>{selectedCampaign.name}</Typography>
                </LineItem>
                <Divider/>
                <LineItem>
                    <Typography variant="h5">Description:</Typography>
                    <Typography>{selectedCampaign.description}</Typography>
                </LineItem>
                <Divider/>
                <LineItem>
                    <Typography variant="h5">Players:</Typography>
                    <Typography>{
                        selectedCampaign.members ?
                        selectedCampaign.members.map(member => <span>{member}</span>)
                        :
                        'No players yet, invite players using the invite link below to get started'
                    }</Typography>
                </LineItem>
                <Divider/>
                <LineItem>
                    <Typography variant="h5">Invite link:</Typography>
                    <Typography>{window.location.href}/join</Typography>
                </LineItem>
            </StyledCard>
        </PageContainer>
    );
}

const StyledCard = styled(Card)`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    flex: 1;
`;

const LineItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    > * {
        width: 50%;
        padding-left: 24px !important;
        padding-right: 24px !important;
        text-align: left;
    }
`;