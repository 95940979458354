import { Button, Card, CircularProgress, Typography } from '@mui/material';
import { API } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Divider from '../../utils/Divider';
import PageContainer from '../../utils/PageContainer';

export default function CampaignJoin(props) {
    const {onCampaignJoin} = props;
    const [campaignName, setCampaignName] = useState(null);
    const [campaignDescription, setCampaignDescription] = useState(null);
    const {campaignId} = useParams();
    const history = useHistory();

    const updateCampaignName = async (id) => {
        const res = await API.get('campaignsApi', `/campaigns/object/${id}`);
        if (res && res.name) {
            setCampaignName(res.name);
            setCampaignDescription(res.description);
        }
        else {
            // TODO: error handling
        }
    } 
    const joinCampaign = async () => {
        const res = await API.put('campaignsApi', `/campaigns/join/${campaignId}`);
        onCampaignJoin();
        history.push(`/campaigns/${campaignId}`);
    };
    useEffect(() => {
        updateCampaignName(campaignId);
    }, [campaignId]);

    return (
        <PageContainer>
            {
                campaignName === null ?
                <CircularProgress/>
                :
                (
                    <StyledCard>
                        <Typography variant="h4">{campaignName}</Typography>
                        <Divider/>
                        {
                            campaignDescription && <Typography>{campaignDescription}</Typography>
                        }
                        <Divider/>
                        <Button onClick={joinCampaign}>Join</Button>
                    </StyledCard>
                )
            }
        </PageContainer>
    )
}

const StyledCard = styled(Card)`
    padding: 16px;
`;